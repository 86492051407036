import admin from './admin.json';
import auth from './auth.json';
import certificate from './certificate.json';
import common from './common.json';
import errors from './errors.json';
import layout from './layout.json';
import management from './management.json';
import page_homepage from './page_homepage.json';
import readingHabits from './readingHabits.json';
import validation from './validation.json';

export { admin, auth, certificate, common, errors, management, layout, page_homepage, readingHabits, validation };
