import { AppShell } from '@mantine/core';
import React, { FunctionComponent } from 'react';

import { DefaultFooter } from '@components/Default/Layout/DefaultFooter';
import { DefaultHeader } from '@components/Default/Layout/DefaultHeader';

export const DefaultWrapper: FunctionComponent = ({ children }) => {
  return (
    <AppShell
      padding="md"
      fixed={false}
      navbarOffsetBreakpoint="md"
      header={<DefaultHeader />}
      footer={<DefaultFooter />}
      styles={(theme) => ({
        root: { display: 'flex', flexDirection: 'column', minHeight: '100vh' },
        body: { flex: 1 },
        main: {
          paddingLeft: 0,
          paddingRight: 0,
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.white[0],
        },
      })}
    >
      {children}
    </AppShell>
  );
};
