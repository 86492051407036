import React, { FunctionComponent } from 'react';

interface IProps {
  small?: boolean;
}

export const Logo: FunctionComponent<IProps> = ({ small = false }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={small ? 50 : 67} viewBox="0 0 716.394 530.739">
      <defs>
        <clipPath id="a" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h841.89v595.276H0z"></path>
        </clipPath>
        <clipPath id="b" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h841.89v595.276H0z"></path>
        </clipPath>
        <clipPath id="d" clipPathUnits="userSpaceOnUse">
          <path d="M0 0h841.89v595.276H0z"></path>
        </clipPath>
        <linearGradient
          id="c"
          x1="0"
          x2="1"
          y1="0"
          y2="0"
          gradientTransform="matrix(555.77118 368.51468 245.67925 -490.45846 93.987 89.841)"
          gradientUnits="userSpaceOnUse"
          spreadMethod="pad"
        >
          <stop offset="0" stopColor="#fbc707"></stop>
          <stop offset="0.316" stopColor="#fbc707"></stop>
          <stop offset="0.6" stopColor="#f03e3c"></stop>
          <stop offset="0.729" stopColor="#ed1443"></stop>
          <stop offset="1" stopColor="#ed1443"></stop>
        </linearGradient>
      </defs>
      <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 -209.66 665.167)">
        <path
          fill="#254aa5"
          d="M162.742 440.614l58.6-296.78.483-2.444 2.614.222 362.8 30.932 3.075.262.965 3.126L688.29 490.31l1.372 4.446-4.38-.411-518.41-48.528-5.064-.474z"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="7.32"
        d="M0 0l104.502 300.463.861 2.475 2.62-.017 363.559-2.371 3.082-.02.505-3.041L525.908-8.307l.718-4.325-4.384.066L3.403-4.862l-5.068.075z"
        transform="matrix(1.32922 -.10461 -.20226 1.35337 7.328 77.682)"
      ></path>
      <g clipPath="url(#b)" transform="matrix(1.33333 0 0 -1.33333 -209.66 665.167)">
        <path
          fill="#7c236a"
          d="M163.491 438.404l57.85-294.566.442-2.251 2.372.013 354.499 2.02 3.733.02.71 3.862 58.102 316.311.689 3.752-3.659-.185-470.452-23.766-5.257-.265z"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="7.32"
        d="M0 0l103.405 298.197.79 2.279 2.406.173 359.52 25.801 3.786.272.134-3.794L481.033 12.15l.13-3.686-3.686-.103L3.56-4.858l-5.296-.148z"
        transform="matrix(1.32922 -.10461 -.20226 1.35337 8.327 80.63)"
      ></path>
      <path
        fill="url(#c)"
        d="M163.957 437.527l56.905-293.951.39-2.019 2.067-.21 353.348-35.873 4.648-.472.213 4.866 14.474 329.417.134 3.041-2.984.002-424.725.405-5.478.006z"
        transform="matrix(1.33333 0 0 -1.33333 -209.66 665.167)"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="7.32"
        d="M0 0l102.351 297.509.703 2.044 2.129.372 364.101 63.485 4.789.835-.521-4.833-35.28-327.268-.326-3.02-3.028-.236L3.747-4.84l-5.562-.435z"
        transform="matrix(1.32922 -.10461 -.20226 1.35337 8.95 81.798)"
      ></path>
      <g clipPath="url(#d)" transform="matrix(1.33333 0 0 -1.33333 -209.66 665.167)">
        <path
          fill="#fff"
          d="M556.864 376.005l-36.657.688-.766-92.597-33.375 1.563-1.085 91.697-36.478.685-.553 16.733-30.965-47.088 42.883-59.447.039-1.355-36.424 1.705-11.944 16.721-14.775 22.186-12.065-21.401-9.667-15.236-36.425 1.705-.138 1.307 35.327 55.029-44.352 56.173-.187 1.801 38.693-.42 13.107-17.872 13.048-20.823 10.484 20.778 10.593 17.405 142.5-1.546zm-219.341-83.391l-28.462 1.333-59.051 62.398 4.408-24.401 5.682-35.704-33.883 1.587-20.205 110.384 30.52-.331 61.717-62.495c-2.624 13.603-3.289 18.772-4.031 23.141l-5.004 38.782 36.153-.392zm-109.286-5.824l33.302-1.689 13.512-84.79 53.224-4.375 3.282-27.253-83.115 7.724zm145.299-122.496l-59.368 118.138 36.833-1.868 16.539-35.248 17.885-43.729 11.235 41.902 11.022 34.201 36.83-1.868-40.89-114.33zm90.302 110.548l33.301-1.689.47-91.138 53.224-4.376-.837-29.258-83.115 7.725z"
        ></path>
      </g>
    </svg>
  );
};
