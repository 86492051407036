import { formatRelative, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-internal-modules
import dateFnsDe from 'date-fns/locale/de';
import i18n from 'i18next';
import { initReactI18next, Trans, useTranslation } from 'react-i18next';

import { de } from '@app/locales';
import { getStackHeightInMM } from '@utilities/bookStack';
import { formatUnit } from '@utilities/Format/number';

i18n.use(initReactI18next).init({
  resources: {
    de,
  },
  lng: 'de',
  fallbackLng: 'de',
  defaultNS: 'common',
});
i18n.services.formatter?.add('unit', (value: any, lng: string | undefined, options: any) => {
  return formatUnit(value, lng || options.fallbackLng);
});

i18n.services.formatter?.add('stacksize', (value: any) => {
  if (typeof value !== 'number') {
    throw new Error('value passed is not a number');
  }
  return getStackHeightInMM(value).toString(10);
});
i18n.services.formatter?.add('parsedate', (value: any) => {
  if (typeof value === 'undefined') {
    console.error('undefined passed to parsedate-formatter');
  }
  if (value instanceof Date) {
    return value as unknown as string;
  }
  return parseISO(value) as unknown as string;
});

i18n.services.formatter?.add('datetorelative', (value: any, _lng) => {
  if (!(value instanceof Date)) {
    console.error('non-date passed to datetorelative-formatter');
    return '';
  }
  return formatRelative(value, new Date(), { locale: dateFnsDe });
});

export default i18n;
export { useTranslation, Trans };
