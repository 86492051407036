export const formatUnit = (value: any, lng: string) => {
  const numberFormatter = new Intl.NumberFormat(lng, {
    maximumFractionDigits: 2,
  });
  // to start value is in mm

  if (value > 1000) {
    return `${numberFormatter.format(value / 1000)} m`;
  }

  if (value > 10) {
    return `${numberFormatter.format(value / 10)} cm`;
  }
  return `${numberFormatter.format(value)} mm`;
};

export const formatNumber = (value: any, lng = 'de') => {
  const numberFormatter = new Intl.NumberFormat(lng, {
    maximumFractionDigits: 2,
  });
  return numberFormatter.format(value);
};
