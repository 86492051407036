import { Anchor, Box, Container, createStyles, Divider, Grid, Group, Image, Stack, Text } from '@mantine/core';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '@app/i18n';
import { LinkedLogo } from '@components/Ui/LinkedLogo';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: 120,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
    borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
  },

  links: {
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export const DefaultFooter: FunctionComponent = () => {
  const { classes } = useStyles();
  const [t] = useTranslation('layout');

  const links = [
    { link: '/impressum', label: t('footer.links.imprint') },
    { link: '/datenschutz', label: t('footer.links.privacyProtection') },
  ];

  const items = links.map(({ link, label }) => {
    if (link) {
      return (
        <Link href={link} key={label} passHref={true} prefetch={false}>
          <Anchor<'a'> color="dimmed" size="sm">
            {label}
          </Anchor>
        </Link>
      );
    }
  });

  return (
    <footer className={classes.footer}>
      <Container size="lg">
        <Stack>
          <Group position="apart">
            <LinkedLogo small={true} />
            <Group>{items}</Group>
          </Group>
          <Divider />
          <Grid gutter="xl">
            <Grid.Col span={12} sm={6}>
              <Stack>
                <Text color="dimmed" size="sm">
                  {t('footer.supportedBy')}
                </Text>
                <Grid gutter="xl">
                  <Grid.Col span={6}>
                    <Box>
                      <Image src="/static/logo_buergerstiftung.svg" alt="Logo Bürgerstiftung" />
                    </Box>
                  </Grid.Col>
                </Grid>
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} sm={6}>
              <Stack>
                <Text color="dimmed" size="sm">
                  {t('footer.realizedBy')}
                </Text>
                <Grid gutter="xl">
                  <Grid.Col span={6}>
                    <Box>
                      <Link href="https://viu-x.de">
                        <a target="_blank">
                          <Image src="/static/logo_viux_footer.svg" alt="Logo vui·x" />
                        </a>
                      </Link>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Box>
                      <Link href="https://www.stadtbibliothek-guetersloh.de" target="_blank">
                        <a target="_blank">
                          <Image
                            src="/static/logo_stadtbibliothek_guetersloh.svg"
                            alt="Logo Stadtbibliothek Gütersloh"
                          />
                        </a>
                      </Link>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </footer>
  );
};
