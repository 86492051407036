import { Container, Group, Header, HeaderProps, Image, Text } from '@mantine/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '@app/i18n';
import { HEADER_HEIGHT, useHeaderStyles } from '@components/Common/styles/headerStyles';
import { LinkedLogo } from '@components/Ui/LinkedLogo';

type HeaderResponsiveProps = Omit<HeaderProps, 'children' | 'height'>;

export const DefaultHeader: FunctionComponent<HeaderResponsiveProps> = (props) => {
  const router = useRouter();
  const [t] = useTranslation('layout');
  const { classes, cx } = useHeaderStyles();

  const links = [
    { link: '/account/login', label: t('header.links.login') },
    { link: '/account/register', label: t('header.links.register') },
  ];

  const items = links.map(({ link, label }) => {
    const activeRoute = router.pathname === link;

    if (link) {
      return (
        <Link href={link} key={label}>
          <a className={cx(classes.link, { [classes.linkActive]: activeRoute })}>{label}</a>
        </Link>
      );
    }
  });

  return (
    <Header height={HEADER_HEIGHT} sx={{ position: 'sticky', top: 0 }} {...props}>
      <Container size="lg" className={classes.header}>
        <LinkedLogo />
        <Group spacing={5}>{items}</Group>
      </Container>
    </Header>
  );
};
