import { createStyles, Group, Text } from '@mantine/core';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import { Logo } from '@components/Ui/Logo';

const useStyles = createStyles((theme) => ({
  logoText: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));

interface IProps {
  href?: string;
  small?: boolean;
}

export const LinkedLogo: FunctionComponent<IProps> = ({ href = '/', small = false }) => {
  const { classes } = useStyles();

  return (
    <Group>
      <Link href={href}>
        <a>
          <Logo small={small} />
        </a>
      </Link>
      <Text size="lg" weight="bold" className={classes.logoText}>
        NEXT LEVEL
      </Text>
    </Group>
  );
};
